@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'tradesmith';
    src: url('./fonts/TradesmithRegular.ttf');
}

@font-face {
    font-family: 'tradesmith-stamp';
    src: url('./fonts/TradesmithStamp.ttf');
}

p {
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
        font-size: .9rem !important;
    }
}

.big-title-stamp {
    font-size: 2rem;
    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
}

.big-text {
    font-size: 2.8rem;
    @include media-breakpoint-up(lg) {
        font-size: 3.5rem !important;
    }
}

.big-text {
    font-size: 2.8rem;
    @include media-breakpoint-up(lg) {
        font-size: 3.5rem !important;
    }
}

.medium-text {
    font-size: 1.2rem !important;
    
    @include media-breakpoint-up(md) {
        line-height: 2.2rem;
        font-size: 1.5rem !important;
    }
}

.text-small {
    font-size: .8rem;

    @include media-breakpoint-down(sm) {
        font-size: .6rem;
    }
}





.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}


