#generator-life {
    padding-top: 2rem !important;
    @include media-breakpoint-up(md) {
        padding-top: 6.5rem !important;
    }
    .da-logo img{
        max-width: 6.5rem;

        @include media-breakpoint-up(md) {
            max-width: 12rem;
        }
    }
    .da-big-text {
        font-size:2rem;
        line-height: 107%;
        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
        }
    }

    .emphasized {
        position: relative;

        &::after {
            content: 'TM';
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: .7rem;
            top: -.5rem;
            position: absolute;
            
            @include media-breakpoint-up(md) {
                font-size: 1.4rem !important;
                top: -1.1rem;
            }
        }

        .paint-underline {
            position: absolute;
            right: -10px;
            bottom: -8px;
            max-width: 12rem;

            @include media-breakpoint-up(md) {
                bottom: -15px;
                right: -2rem;
                max-width: 28rem;
            }
        }

    }
}