#dieselalysis-experience {
    .column {
        &.left {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 66%;
            }
        }
        &.right {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: 32% ;
            }
            
        }
    }
}