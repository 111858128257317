.core-values {
        .core {
                position: relative;
                width: 48% !important;
                min-height:  124px !important;

                .number {
                        position: absolute;
                        left: 11px;
                        top: -20px;
                        width: 40px;
                        height: 40px;
                        background-color: $gold;
                        transform: scale(.9);
                }

                &.yellow {
                        background-color: $yellow-faded;
                        .number {
                                background-color: $primary;
                        }
                }

                .name {
                        line-height: 100%;
                        @include media-breakpoint-down(sm) {
                                font-size: 1.1rem !important;
                        }
                }
        }
}