#men-at-work {
    .column {
        width: 48%;
        @include media-breakpoint-up(sm) {
            width: 32%;
        }

        .tile {
            margin-bottom: 6%;
        }

        &.column-2 {
            @include media-breakpoint-down(sm) {
                width: 100%;
                justify-content: space-between;

            }
            .tile {
                @include media-breakpoint-down(sm){
                    width: 48%;
                }
            }
        }
    }
}

// .dsls-image-viewer {
//     #ReactSimpleImageViewer {
//         z-index: 1000 !important;
//         background-color: rgba(#000, .9);
//     }
// }