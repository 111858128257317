// Fonts
$roboto: 'Roboto', sans-serif;
$roboto-condensed: 'Roboto Condensed', sans-serif;
$tradesmith-regular: 'tradesmith', sans-serif;
$tradesmith-stamp: 'tradesmith-stamp', sans-serif;

//colors
$danger: #A80000;
$primary: #DFB924;
$darker-primary: #D3AB0B;
$gold: #B8A766;
$white: #ffffff;
$dirty-white: #DADEE2;
$black: #000000;
$gray: #597084;
$gray2: #D9D9D9;
$yellow-faded: #FFF9E0;



$body-bg: $black;
// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1082px,
);

// container-breakpoints
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1082px,
);

$border-width:               1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

// $border-color:                $gray-300;
$btn-rounded-radius:         10rem;
$border-radius:               .25rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            .3rem;
$border-radius-pill:          50rem;

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
// $input-btn-font-family:       null;
// $input-btn-font-size:         $font-size-base;
// $input-btn-line-height:       $line-height-base;

$input-btn-focus-width:         .25rem;
$input-btn-focus-color-opacity: .25;
// $input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur:          0;
// $input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
// $input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
// $input-btn-font-size-lg:      $font-size-lg;

// $input-btn-border-width:      $border-width;

// transitions
$all-standard-transition: all 600ms;

// offcanvas
$offcanvas-horizontal-width: 310px;