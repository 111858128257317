.social-media {
    .img-wrap {
        width: 35px;
        height: 35px;
    }

    &::after {
        content: "";
        border-right: 1px solid $white;
        display: inline-block;
        width: 1px;
        height: 35px;
        margin: 0 1rem;
    }

    &.hide-after {
        &::after {
            display: none;
        }
    }

    &.hide-after-on-lg {
        &::after {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &.hide-after {
        &::after {
            display: none !important;
        }
    }
}