.underdev {
    position: relative;
    height: 100vh;
    background-image: url('./../../images/background/bg-smog.png');
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        font-family: $tradesmith-stamp
    }
}