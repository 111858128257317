.hero {
    background: url('../../images/background/bg-smog.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding-top: 60px;
    margin-top: -120px;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(sm) {
        height: 85vh;
    }

    .title {
        h1 {
            font-size: 3.75rem;
            @include media-breakpoint-up(md) { 
                font-size: 4.75rem
            }

            @include media-breakpoint-up(lg) { 
                font-size: 7.75rem 
            }

            @include media-breakpoint-up(xl) { 
                font-size: 9.75rem 
            }
        }
    }

    .title-sub {
        h2 {
            @include media-breakpoint-down(md) {
                font-size: 1rem !important;
            }
        }
    }

    .btn-wrap {
        .btn {
            @include media-breakpoint-down(sm) {
                font-size: 1.1rem !important;
            }
        }
    }
}