.menu {
    .link-block {
        transition: $all-standard-transition;
        a, button {
            color: $black;
        }

        &:hover {
            background: $darker-primary;
        }
    }

    .closeOpen {

        position: absolute;
        left: -48px;
        top: 50%;
    }
    
}