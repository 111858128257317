#dsls-feed-back {
    .dsls-slider {
        .swiper-slide {
            height: auto;
            display: flex;
        }
        .person {
            .top {
                border-bottom: 1px solid $primary;
                .img {
                    width: 6.5rem;
                    @include media-breakpoint-up(md) {
                        width: 8.5rem;
                    }
                }
                .message {
                    p {
                        @include media-breakpoint-up(md) {
                            font-size: 1.2rem;

                        }
                    }
                }
            }

            .footer {
                .person-profile {
                    .fullName {
                        line-height: 80%;
                    }
                    .occupation {
                        font-size: 0.8rem;
                    }
                }

                .rating {
                    img {
                        margin-left: .5rem;
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }
    }
}