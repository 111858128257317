#fuel-recycle {
    .inner-wrap {
        border-top: 2px solid $primary;
        border-bottom: 2px solid $primary;
        
        .left {
            max-width: 3.5rem;
            @include media-breakpoint-up(md) {
                max-width: 7.5rem;
            }
            
        }
    }
}