.text-tradesmith-stamp {
    font-family: $tradesmith-stamp;
}

.text-tradesmith-regular {
    font-family: $tradesmith-regular;
}

.text-roboto-condensed {
    font-family: $roboto-condensed;
}

// button custom utils
.btn-rounded {
    border-radius: $btn-rounded-radius !important;
}

.pull-top {
    margin-top: -1rem !important;
}

.text-gray {
    color: $gray;
}

.text-default {
    color: black
}

.bg-frost-dark {
    background-color: rgba(#000, .85) !important;
}