.flyweird {
    position: fixed;
    z-index: 1000;
    min-width: 100%;
    bottom: 39px;
    opacity: .85;
    transform: scale(.8);
    transform-origin: right bottom;

    &:hover {
        opacity: 1;
    }

    .flyweird-btn-wrap {
        position: relative;
        z-index: 5;
        right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    @include media-breakpoint-down(sm) {
        display: none !important;
    }
  
}