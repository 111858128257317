#dsls-benefits {
    .headline {
        h1 {
            font-size: 10rem;
            line-height: 75%;
            @include media-breakpoint-up(lg) {
                font-size: 17.5rem !important;
            }
        }

        h4 {
            line-height: 100%;
            font-size: 2.5rem;
            @include media-breakpoint-up(lg) {
                line-height: 110%;
                font-size: 4rem;
            }
            
        }
    }

    .right {
        max-width: 22rem;
        width: 100%;
        @include media-breakpoint-up(lg) {
            max-width: 26rem;
            margin-left: 1rem;
        }
    }

    .bolted {
        max-width: 25rem;
        margin-bottom: .9rem;
        img {
            max-width: 1.5rem;
            @include media-breakpoint-up(lg) {
                max-width: 3.5rem;
            }
        }

        h4 {
            font-size: 1.5rem;
            @include media-breakpoint-up(lg) {
                font-size: 2.1rem;
            }
        }
    }
}