.dts-slide {
    .nav-round {
        width: 2rem;
        height: 2rem;
        background: $gray2;
        border-radius: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:hover {
            background-color: $primary;
        }

        @include media-breakpoint-up(md) {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}