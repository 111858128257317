.dts-services-logos {
    .img-wrap {
        width: 100% !important;
        max-width: 150px !important;
        img {
            @include media-breakpoint-down(sm) {
                width: 100% !important;
            }
        }
    }
}