#diesel-fuel {
    margin-top: 7rem;
    @include media-breakpoint-up(md) {
        margin-top: 3rem;
    }

    .df-text {
        text-align: center;
        font-size: 1rem !important;
        
        @include media-breakpoint-up(md) {
            font-size: 1.2rem !important;
            text-align: left;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.2rem !important;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.5rem !important;
            text-align: left;
        }
    }

    .left {
        position: relative;

        .d-fuel-flask {
            bottom: 0;

            @include media-breakpoint-down(md) {
                max-width: 21rem;
                margin-top: -10rem;
                position: static;
            }

            @include media-breakpoint-down(sm) {
                max-width: 16rem;
                margin-top: -8rem;
                position: static;
            }

            @include media-breakpoint-up(md) {
                max-width: 16rem;
                position: absolute;
                top: 21%;
                transform: scale(1.5);
            }

            @include media-breakpoint-up(lg) {
                max-width: 12rem;
                top: 21%;
                transform: scale(2.3);
            }

            @include media-breakpoint-up(xl) {
                max-width: 12rem;
                top: 21%;
                transform: scale(2.4);
            }
        }
    }
}