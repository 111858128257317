.services-deck {

        .servicesCard {
                @include media-breakpoint-down(md) {
                        flex-grow: 1;
                }
               
                .img-wrap {
                        width: 100%;
                        max-width: 150px;
                }
                .title-wrap {
                        width: 150px;
                        @include media-breakpoint-down(sm) {
                                width: 130px;
                        }

                        p {
                                font-size: 1rem !important;
                                @include media-breakpoint-down(sm) {
                                        font-size: .9rem !important;
                                }
                        }
                }
        }
}

.decks {
        .cta-large {
                text-align: center;
                min-width: 230px;
                max-height: 117px;
                font-size: 1.81rem !important;

                @include media-breakpoint-down(md) {
                        width: 100% !important;
                        font-size: 1.3rem !important;
                }
        }
}

.residential-industrial {
        .big-text-2 {
                font-size: 2.8rem;
                @include media-breakpoint-up(lg) {
                    font-size: 3.1rem !important;
                }
        }
}

.get-estimate {
        min-height: 95px;
        font-size: 1.6rem;
        color: $black;
        border: 1px solid $primary;
        transition: $all-standard-transition;

        &:hover {
                background-color: $white !important;
                color: $black !important;
                border-color:$white;
        }
        @include media-breakpoint-down(sm) {
                min-height: 65px;
        }
}
