// dsls-modal
.cx-modal-dieselalysis {
    .modal-dialog {
        padding: 1rem;
    }
    .modal-content {
        max-width: 30rem;
        border-radius: 1rem;
        overflow: hidden;
        background-color: rgba($black, .7);
    }
    .modal-body {
        padding: 0;
    }
}

// dsls-modal backdrop
.dsls-modal-backdrop {
    &.show {
        opacity: 0.65;
    }
}

// dsls-modal footer
.dieselalysis-footer {
    .btn-wrap {
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
        display: flex;

        .dsls-btn {
            padding: .9rem;
            font-size: 1.2rem;
            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
                padding: 1.2rem;
            }
        }

        .gray {
            color: #565656;
            background-color: #999A9D;

            &:hover {
                background-color: $gray-300;
            }
        }

        .primary {
            color: $white;
            background-color: $primary;
            text-decoration: none;

            &:hover {
                background-color: $darker-primary;
            }
        }
    }
}

// dsls-modal checkbox
.modal-hide-counter {
    padding: .6rem 1rem;
    
    .xhours-hide{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #dsls-checkbox {
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid $white;
        border-radius: 0;
        background-color: transparent;
        &:checked {
            background-color: transparent;
        }
    }
    label {
        color: $white;
        font-size: .8rem;
        margin-bottom: -0.3rem;
        margin-left: 1rem;
    }
}